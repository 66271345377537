import { Card as MuiCard } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'

import { WebsiteButtonStyle, WebsiteColorPalette } from '@/shared/models/Website'
import { useClub } from 'contexts/club';
import { ITheme } from '@/shared/styles/MuiTheme';

interface IStyleProps {
    buttonStyle: WebsiteButtonStyle;
    isHighPalette: boolean;
}

const cardStyles = makeStyles((theme: ITheme) => createStyles({
    card: {
        backgroundColor: theme.props.sectionMode.card,
        borderRadius: ({ buttonStyle }: IStyleProps) => buttonStyle === WebsiteButtonStyle.SQUARE_CORNERS ? 0 : '8px',
        border: ({ isHighPalette }) => isHighPalette ? `1px solid ${theme.props.sectionMode.text_background}` : 0,
        display: 'block'
    }
}))

interface IProps {
    children: React.ReactNode;
}

export default function Card({
    children
}: IProps): JSX.Element {
    const { website } = useClub()
    const isHighPalette = website?.color_palette === WebsiteColorPalette.HIGH_PALETTE
    const classes = cardStyles({ buttonStyle: website?.button_style, isHighPalette })

    return (
        <MuiCard className={classes.card}>
            {children}
        </MuiCard>
    )
}