import React, { useState } from 'react'
import Link from 'next/link'
import { makeStyles } from '@material-ui/core/styles'
import MUITabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { Paragraph2 } from '../Typography';
interface ITab {
    title: string | React.ReactNode;
    link?: string;
    tab?: string
    native?: boolean
}
interface ITabsProps {
    tabs: ITab[];
    selectedIndex?: number;
    onClick?(tab: ITab, index: number): void
}

const useStyles = makeStyles({
    root: {
        minHeight: '48px',
    },
    tab: {
        flex: 1,
        textAlign: 'center',
        '& span': {
            whiteSpace: 'nowrap'
        }
    },
    link: {
        textDecoration: 'none',
    },
    activeTab: {}
});

export default function Tabs({
    tabs,
    selectedIndex,
    onClick
}: ITabsProps): React.ReactElement {
    const classes = useStyles()
    const [ value, setValue ] = useState(selectedIndex)

    function renderTab(tab, index): JSX.Element {
        return (
            <Tab
                onClick={() => {
                    setValue(index)
                    onClick?.(tab, index)
                }}
                disableRipple key={index}
                label={index === value ? <Paragraph2><b>{tab.title}</b></Paragraph2> : <Paragraph2>{tab.title}</Paragraph2>}
            />
        )
    }
    
    return (
        <MUITabs
            className={classes.root}
            value={value}
        >
            {tabs.map((tab, index) => {
                if (tab.link) {
                    return (
                        <div key={index} className={`${classes.tab} ${index === value ? classes.activeTab : ''}`} onClick={() => setValue(index)}>
                            {tab?.native ? (
                                <a className={classes.link} href={tab.link}>
                                    {renderTab(tab, index)}
                                </a>
                            ): (
                                <Link href={tab.link}>
                                    {renderTab(tab, index)}
                                </Link>
                            )}
                        </div>
                    )    
                } 
                return renderTab(tab, index)
            })}
        </MUITabs>
    )
}